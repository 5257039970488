import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage/Home/Home";
import PartnershipPage from "./components/Partnership/PartnershipPage/PartnershipPage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Aboutus from "./components/AboutUsPage/Aboutus/Aboutus";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import FAndQ from "./components/FAndQ/FAndQ";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route index path="/" element={<HomePage />} />
          <Route index path="/partnership" element={<PartnershipPage />} />
          <Route index path="/about-us" element={<Aboutus />} />
          <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route index path="/fanda" element={<FAndQ />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
