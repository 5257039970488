import React, { useEffect } from "react";
import styles from "./IntroduceTruck.module.css";
import introtruck from "../../../assets/introtruck.png";
import WhyChoseusIntro from "../WhyChoseusIntro/WhyChoseusIntro";
import Review from "../Review/Review";
import logo from "../../../assets/baccc.png";

import Aos from "aos";
import "aos/dist/aos.css";

export default function IntroduceTruck(props) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className={styles.introtruckdivmainnnnn}>
      <div className={styles.introtruckdiv}>
        <div className={styles.introtruckdivimg}>
          <img src={introtruck} alt="introtruck" />
        </div>
        <div className={styles.introtruckdivimgcontent}>
          <div data-aos="fade-right">
            <h2>
              Introducing
              <span>
                <img
                  className={styles.introtruckdivimg}
                  src={logo}
                  alt="logo"
                />
              </span>
              for drivers
            </h2>
          </div>
          <div data-aos="fade-left">
            <p>
              Owners of truck fleets who have not yet incorporated digital
              technology into their operations or are dissatisfied
              <br /> with their current platform should become our partners
              today.
            </p>
          </div>
          <div>
            <button onClick={props.handleOpen} className={styles.btn}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <WhyChoseusIntro />
      <Review />
    </div>
  );
}
