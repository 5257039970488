import React, { useEffect, useState } from "react";
import styles from "./EVFleet.module.css";
import group124 from "../../../assets/ev.png";
import group1246 from "../../../assets/group1246.png";
import vector22 from "../../../assets/vector22.png";
import rt from "../../../assets/rt.png";
import Aos from "aos";
import "aos/dist/aos.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arwcstmrt from "../../../assets/arwcstmrt.png";
import nxtarw from "../../../assets/arrwleft.png";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import no1 from "../../../assets/no1.png";
import no2 from "../../../assets/no2.png";

export default function EVFleet() {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <div className={styles.classNameimg}>
          <ChevronLeftIcon className={styles.classNameimgc} />
        </div>

        {/* <img src={nxtarw} className={styles.classNameimg} alt="arw1" /> */}
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <div className={styles.classNameimg}>
          <ChevronRightIcon className={styles.classNameimgc} />
        </div>
        {/* <img src={arwcstmrt} className={styles.classNameimg} alt="nxtarw" /> */}
      </div>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={styles.evfltimgh2mainn}>
      <div className={styles.evfltimgh2}>
        <h2>
          <span className={styles.evfltimgh2ev}>
            <img src={vector22} alt="vector22" />
            EV
          </span>
          fleet for green deliveries
        </h2>
      </div>
      <div className={styles.evfltslider}>
        <div data-aos="zoom-in" className={styles.evfltimgh2mg10111}>
          <h4>
            Collaborate with us for sustainable Mobility by deploying our
            e-trucks for your delivery needs.
          </h4>
          <p>
            <span>
              <img src={rt} alt="rt" />
            </span>
            Trained drivers for efficient deliveries.
          </p>
          <p>
            <span>
              <img src={rt} alt="rt" />
            </span>
            Trained workforce for COD handling.
          </p>{" "}
          <p>
            <span>
              <img src={rt} alt="rt" />
            </span>
            Route optimization for quick deliveries.
          </p>
          <p>
            <span>
              <img src={rt} alt="rt" />
            </span>
            Verified truck, deliveries and helpers.
          </p>
          <p>
            <span>
              <img src={rt} alt="rt" />
            </span>
            API integration for real time tracking and dashboard access.
          </p>
        </div>
        <div className={styles.evfltslider2}>
          <Slider {...settings}>
            <div className={styles.evfltimgh2mg102012}>
              <img src={no1} alt="group124" />
            </div>
            <div className={styles.evfltimgh2mg102012}>
              <img src={no2} alt="group124" />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
