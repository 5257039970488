import React, { useEffect } from "react";
import styles from "./Frthpage.module.css";
import page from "../../../assets/slideback.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import f1 from "../../../assets/eauto.png";
import f2 from "../../../assets/normalaut.png";
import f3 from "../../../assets/oppp.png";
import f4 from "../../../assets/tataaaaa.png";

import f5 from "../../../assets/pp.png";
import f6 from "../../../assets/cc.png";
import f7 from "../../../assets/bb.png";
import f8 from "../../../assets/ll.png";
// import arw1 from "../../../assets/arw1.png";

import arwcstmrt from "../../../assets/arwcstmrt.png";
import nxtarw from "../../../assets/arrwleft.png";

import Aos from "aos";
import "aos/dist/aos.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={nxtarw} className={styles.classNameimg} alt="arw1" />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={arwcstmrt} className={styles.classNameimg} alt="nxtarw" />
    </div>
  );
}

export default function Frthpage() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const myStyle = {};
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles.howitwrksmaindivbgmain}>
      <div className={styles.howitwrksmaindivbg} style={myStyle}>
        <img src={page} alt="howitworks" />
      </div>
      <div className={styles.howitwrksmaindivbg1}></div>
      <div className={styles.howitwrksmaindivcontent}>
        <div className={styles.howitwrksmaindivcontent1}>
          <div className={styles.howitwrksmaindivcontent1imgh2}>
            <h2>Move with the best range of Vehicles</h2>
          </div>
          <Slider {...settings}>
            <div
              data-aos="fade-up"
              className={styles.howitwrksmaindivcontent1imgh2slide}
            >
              <div className={styles.imagediv}>
                <img src={f1} alt="f1" />
              </div>
              <div className={styles.contentdiv}>
                <h2>EV</h2>
                <p>3 wheeler</p>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className={styles.howitwrksmaindivcontent1imgh2slide}
            >
              <div className={styles.imagediv}>
                <img src={f2} alt="f1" />
              </div>
              <div className={styles.contentdiv}>
                <h2>Regular</h2>
                <p>3 Wheeler</p>
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="6000"
              className={styles.howitwrksmaindivcontent1imgh2slide}
            >
              <div className={styles.imagediv}>
                <img src={f8} alt="f1" />
              </div>
              <div className={styles.contentdiv}>
                <h2>TATA</h2>
                <p>407</p>
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="5000"
              className={styles.howitwrksmaindivcontent1imgh2slide}
            >
              <div className={styles.imagediv}>
                <img src={f4} alt="f1" />
              </div>
              <div className={styles.contentdiv}>
                <h2>Tata Ace</h2>
                <p>Closed Carrier</p>
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="4000"
              className={styles.howitwrksmaindivcontent1imgh2slide}
            >
              <div className={styles.imagediv}>
                <img src={f3} alt="f1" />
              </div>
              <div className={styles.contentdiv}>
                <h2>Tata Ace</h2>
                <p>Open Carrier</p>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="6000"
              className={styles.howitwrksmaindivcontent1imgh2slide}
            >
              <div className={styles.imagediv}>
                <img src={f6} alt="f1" />
              </div>
              <div className={styles.contentdiv}>
                <h2>8ft 1ton</h2>
                <p>semi truck</p>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="6000"
              className={styles.howitwrksmaindivcontent1imgh2slide}
            >
              <div className={styles.imagediv}>
                <img src={f5} alt="f1" />
              </div>
              <div className={styles.contentdiv}>
                <h2>8ft 1ton</h2>
                <p>Pick up truck</p>
              </div>
            </div>

            <div className={styles.howitwrksmaindivcontent1imgh2slide}>
              <div className={styles.imagediv}>
                <img src={f7} alt="f1" />
              </div>
              <div className={styles.contentdiv}>
                <h2>Eicher Pro</h2>
                <p>19ft 6ton</p>
                <p>Semi truck</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
