import React, { useState, useEffect } from "react";
import styles from "./DeliveryComp.module.css";
import truck from "../../assets/truck.png";
import cx from "classnames";
import Aos from "aos";
import "aos/dist/aos.css";
import ontimeillustration from "../../assets/a.png";
import thinklogisticsilllustration from "../../assets/b.png";
import savemoreillustration from "../../assets/c.png";
import allllustration from "../../assets/d.png";
import truckingq from "../../assets/e.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import iconnn from "../../assets/iconnn.png";
import arwcstmrt from "../../assets/arwcstmrt.png";
import arrwleft from "../../assets/arrwleft.png";

export default function DeliveryComp() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arrwleft} className={styles.classNameimg1} alt="arw1" />
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arwcstmrt} className={styles.classNameimg1} alt="nxtarw" />
      </div>
    );
  }
  const [localHead, setLocalhead] = useState(1);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
  };
  const mcdc = [
    {
      id: 1,
      head: "Always on time",
      img: ontimeillustration,
      content:
        "Our service levels offer the best customer experience possible and we have a reputation for completing customer/Client tasks on schedule.",
    },
    {
      id: 2,
      head: "An all-inclusive remedy",
      img: thinklogisticsilllustration,
      content:
        "This is a one-stop solution for easily scheduling on-demand bookings, and you won't have to deal with the hassles of managing multiple vendors, whether it be first-mile, mid-mile and last mile.",
    },
    {
      id: 3,
      head: "Spend less and Earn More",
      img: savemoreillustration,
      content:
        "With our optimized efficiencies, our clients saved money on their logistical expenditures. This means your company may spend less, save more, and increase its profits.",
    },
    {
      id: 4,
      head: "Part of your Success journey",
      img: allllustration,
      content:
        "Our coverage extends across 500+ PIN codes in India. Our expertise is in our extensive reach in Tier II and III cities, particularly in South India. We will always accompany you on your success Journey.",
    },
    {
      id: 5,
      head: "Platform for better decisions",
      img: truckingq,
      content:
        "With KPI knowledge offered by our powerful and comprehensive technology-based API’s and dashboards, you can make smarter and faster decisions to improve your business at every level.",
    },
  ];

  const changeHead = (i) => {
    setLocalhead(i);
  };

  return (
    <div className={styles.dcmaindiv}>
      <div className={styles.mcmaindiv}>
        <div className={styles.mcmaindiv2}>
          <div className={styles.mcmaindiviconn}>
            <img src={iconnn} alt="iconn" />
          </div>
          <div className={styles.mcmaindiv222}>
            {mcdc.map((res, i) => (
              <div className={styles.mcmaindiv2223}>
                <p
                  onClick={() => changeHead(res.id)}
                  className={cx(
                    `${styles.mcmaindiv2p} ${
                      res.id === localHead ? [styles.mcmaindiv2active] : ""
                    }`
                  )}
                >
                  {res.head}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.mcmaindiv4}>
          {mcdc.map((res, i) =>
            res.id === localHead ? (
              <div className={styles.mcmaindiv4imghpp}>
                <img data-aos="fade-zoom-in" src={res.img} alt={res.id} />
                <div>
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    {res.head}
                  </h2>
                  <p data-aos="fade-zoom-in" data-aos-easing="ease-in">
                    {res.content}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </div>

      {/* /////////////////////Mobile View//////////////////// */}

      <div className={styles.mcmaindiv412imgh2slider}>
        <Slider {...settings}>
          {mcdc.map((res, i) => (
            <div className={styles.mcmaindiv412imgh2}>
              <img src={res.img} alt={res.id} />
              <div>
                <h2>{res.head}</h2>
                <p>{res.content}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
