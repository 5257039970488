import React, { useEffect } from "react";
import styles from "./HowItWorks.module.css";

import pathh from "../../../assets/lottinew.json";
import Lottie from "react-lottie";
import frm1 from "../../../assets/apii.png";
import frm2 from "../../../assets/packagee.png";
import frm3 from "../../../assets/realtrck.png";
import frm4 from "../../../assets/suces.png";

import videoback from "../../../assets/videoback.png";

import bggggd from "../../../assets/bggggd.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function HowItWorks() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const topup = () => {
    window.scrollTo(0, 0);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pathh,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={styles.howitwrkdivvv} id="business">
      <div className={styles.howitwrkdivvvdiv}>
        <img src={videoback} alt="videoback" />
      </div>
      <div className={styles.lottidiv}>
        {" "}
        <Lottie options={defaultOptions} loop={true} />
      </div>
      <div className={styles.howitwrkdivvvimgmob}>
        <div className={styles.howitwrkdivvvimg}>
          <img
            src={bggggd}
            alt="howbck"
            className={styles.howitwrkdivvvimgdivv}
          />
        </div>
        <div className={styles.howitwrksmaindivbgmin}>
          <div className={styles.howitwrksh1maindiv}>
            <h1>How it Works</h1>
          </div>
          <div className={styles.howitwrkshmattermaindiv}>
            <div
              data-aos="fade-right"
              className={styles.howitwrkshmattermaindiv2new}
            >
              <div className={styles.howitwrkshmattermaindiv2}>
                <div className={styles.howitwrkshmattermaindiv2img1}>
                  <img src={frm1} alt="frm1" />
                </div>
                <div>
                  <h2>Integrate with Trukrr API’s</h2>
                  <p>
                    Easy assisted integration with our APIs. Average integration
                    time is 12 hours.
                  </p>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className={styles.howitwrkshmattermaindiv2new2}
            >
              <div className={styles.howitwrkshmattermaindiv2222}>
                <div className={styles.howitwrkshmattermaindiv2222m}>
                  <h2>Send packages</h2>
                  <p style={{ textAlign: "end" }}>
                    Send packages to your customers and get a delivery
                    confirmation.
                  </p>
                </div>
                <div className={styles.howitwrkshmattermaindiv2img12}>
                  <img src={frm2} alt="frm2" />
                </div>
              </div>
            </div>

            <div
              data-aos="fade-right"
              className={styles.howitwrkshmattermaindiv2new}
            >
              {" "}
              <div className={styles.howitwrkshmattermaindiv2}>
                <div className={styles.howitwrkshmattermaindiv2img13}>
                  <img src={frm3} alt="frm3" />
                </div>
                <div>
                  {" "}
                  <h2>Real time tracking</h2>
                  <p>
                    Track the status of your package at all times from the
                    dashboard.
                  </p>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className={styles.howitwrkshmattermaindiv2new2}
            >
              <div className={styles.howitwrkshmattermaindiv2222}>
                <div className={styles.howitwrkshmattermaindiv2222m}>
                  <h2>Delivered sucessfully</h2>
                  <p style={{ textAlign: "end" }}>
                    Our tech enabled platform and huge reach of network partners
                    in Tier II and III cities ensures 100% successful delivery.
                  </p>
                </div>
                <div className={styles.howitwrkshmattermaindiv2img14}>
                  <img src={frm4} alt="frm4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
