import React, { useEffect } from "react";
import styles from "./PartnerReview.module.css";
import arw1 from "../../../assets/arw1.png";
import nxtarw from "../../../assets/nxtarw.png";
import zomatoo from "../../../assets/zomatoo.png";
import waycool from "../../../assets/waycool.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function PartnerReview() {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arw1} className={styles.classReview} alt="arw1" />
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={nxtarw} className={styles.classReview} alt="nxtarw" />
      </div>
    );
  }
  const reviews = [
    {
      image: zomatoo,
      name: "Zomato",
      city: "Hyderabad",
      qoute:
        "“Their staff is knowledgeable and their response time is prompt. We look forward to continuing working with Trukrr for our logistic needs in the future.”",
    },
    {
      image: waycool,
      name: "Way Cool",
      city: "Hyderabad",
      qoute:
        "“My experience with Trukrr has been of good service and compliance. They are always checking the status and following up on all our shipments. Thank you Trukrr for all your support.”",
    },
  ];
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
  };
  return (
    <div className={styles.reviewsmaindiv}>
      <div className={styles.reviewsmaindiv1}>
        <div className={styles.reviewsmaindivh2}>
          <h2>Take a look at what our partners say</h2>
        </div>
        <div className={styles.reviewsmaindivslidermaindivblw}>
          <Slider {...settings}>
            {reviews.map((res, i) => (
              <div className={styles.reviewsmaindivslidermaindiv}>
                <div className={styles.reviewsmaindivimg}>
                  <img src={res.image} alt={res.name} />
                </div>
                <div className={styles.reviewsmaindivcontent}>
                  <div className={styles.reviewsmaindivnamecity}>
                    <div>
                      <p>{res.name}-</p>{" "}
                    </div>
                    <div>
                      <p>{res.city}</p>
                    </div>
                  </div>
                  <div
                    style={{ width: "90%" }}
                    className={styles.reviewsmaindivaoute}
                  >
                    <p>{res.qoute}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
