import React from "react";
import NavBar from "../../NavBar/NavBar";
import DelveryMoving from "../DelveryMoving/DelveryMoving";
import HowItWorks from "../HowItWorks/HowItWorks";
import LandingPage from "../LandingPage/LandingPage";
import GetInTouch from "../GetInTouch/GetInTouch";
import Clients from "../Clients/Clients";
import Footer from "../../Footer/Footer";
import Frthpage from "../Frthpage/Frthpage";
import EVFleet from "../EVFleet/EVFleet";
import Styles from "./Home.module.css";
import bg from "../../../assets/vectrr.png";

export default function Home() {
  return (
    <>
      <NavBar
        style={{
          position: "sticky",
          zIndex: "5",
          width: "100%",
          backgroundColor: "transparent",
        }}
        styles={{ color: "#000" }}
      />
      <LandingPage />
      <div id="business">
        <HowItWorks />
      </div>

      <DelveryMoving />
      <EVFleet />
      <Frthpage />
      <Clients />

      {/* <FirstMail /> */}
      {/* <Spyindicator /> */}
      {/* <GetInTouch /> */}
      <Footer />
    </>
  );
}
