import React, { useEffect } from "react";
import styles from "./AboutusMain.module.css";
import aboutus from "../../../assets/aboutus.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function AboutusMain() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={styles.aboutusmaindiv}>
      <div className={styles.aboutusmaindiv2}>
        <div className={styles.aboutusmaindivh2}>
          <h2>About us</h2>
        </div>
        <div data-aos="zoom-in-down" className={styles.aboutusmaindivimg}>
          <img src={aboutus} alt="aboutus" />
        </div>
        <div data-aos="fade-up" className={styles.aboutusmaindivcontent}>
          <div>
            <p>
              <span>Trukkr’s</span> mission is to make deliveries seamless and
              sustainable. Our objective is to become a go-to partner for
              businesses by taking care of all thier logistic needs and
              providing them with unparalleled solutions acceding to our core
              values of reliability, accuracy, integrity and quality.
            </p>
          </div>
          <div>
            <p>
              The world has seen a massive surge in demand for truck deliveries
              in the last two years. Whether it’s e-commerce, food, retail,
              grocery or home delivery services, having the right logistic
              partner will put the business on the winning wheel. Not just being
              the right logistics partner but we want to create a meaningful
              impact by also being socially responsible towards the environment
              to limit the devastating impacts of supply chain activities on
              this planet. Adapting to electric vehicle fleet is one such major
              road to make this possible.
            </p>
          </div>
          <div>
            <p>
              Our team is deeply passionate about delivering high quality
              services and creating social impact. At Trukrr, we are committed
              to fabricating success stories for your businesses and our planet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
