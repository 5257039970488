import React from "react";
import Styles from "./Footer.module.css";
import logo from "../../assets/flo.png";
import logorvr from "../../assets/baccc.png";

import mail from "../../assets/mail.png";
import phone from "../../assets/phone.png";
import mappin from "../../assets/map-pin.png";

import { Link, NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className={Styles.container}>
      <div className={Styles.containerimgimg}>
        <img src={logorvr} alt="logo" />
      </div>
      <div className={Styles.containerimgimg2}>
        <div className={Styles.containerimg12}>
          <div className={Styles.containerimg12map}>
            <div>
              <img src={mail} alt="mail" />
            </div>
            <div>
              <p>Support@trukrr.com</p>
            </div>
          </div>
          <div className={Styles.containerimg12map}>
            <div>
              <img src={phone} alt="phone" />
            </div>
            <div>
              <p>+91 7386667343</p>
            </div>
          </div>
          <div className={Styles.containerimg12map}>
            <div>
              <img src={mappin} alt="mappin" />
            </div>
            <div>
              <p>
                Lewis Workspace #18, Off Millers-Nandidurga Road Jayamahal, CBD,
                Benson Town, Bengaluru, Karnataka 560046
              </p>
            </div>
          </div>
        </div>
        <div className={Styles.containerimg}>
          <img src={logorvr} alt="logo" />
          <p>Copyrights © All rights reserved Tekkr Technologies Pvt Ltd</p>
        </div>
        <div className={Styles.containerimg2}>
          <Link className="footertext" tag={Link} to="/fanda">
            FAQs
          </Link>
          <Link className="footertext" tag={Link} to="/privacy-policy">
            Privacy Policy
          </Link>

          {/* <a className="footertext" href="#">
          Contact us
        </a> */}
        </div>
      </div>

      <div className={Styles.mobileCopyright}>
        <p>Copyrights © All rights reserved Tekkr Technologies Pvt Ltd</p>
      </div>
    </div>
  );
}
