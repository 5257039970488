import React, { useEffect } from "react";
import styles from "./Review.module.css";
// import page from "../../../assets/trcukback.png";
import arw1 from "../../../assets/arw1.png";
import nxtarw from "../../../assets/nxtarw.png";
import imgg1 from "../../../assets/img1.png";
import imgg2 from "../../../assets/img2.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Review() {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arw1} className={styles.classReview} alt="arw1" />
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={nxtarw} className={styles.classReview} alt="nxtarw" />
      </div>
    );
  }
  const reviews = [
    {
      image: imgg1,
      name: "Surya",
      city: "Hyderabad",
      qoute:
        "“I love working for Trukrr because they treat drivers like family. They are honest and fair. Everyone is approachable from the office.”",
    },
    {
      image: imgg2,
      name: "Avinash",
      city: "Hyderabad",
      qoute:
        "“I like working at trucker because they treat me like a member of their family the employee said. when it come to load they give me choice. if you have a problem, wether it be at home or work they have a open door policy and will work with you to solve it.”",
    },
  ];
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
  };
  return (
    <div className={styles.reviewsmaindiv}>
      <div className={styles.reviewsmaindiv1}>
        <div className={styles.reviewsmaindivh2}>
          <h2>Take a look at what our drivers say</h2>
        </div>
        <div className={styles.reviewsmaindivslidermaindivblw}>
          <Slider {...settings}>
            {reviews.map((res, i) => (
              <div className={styles.reviewsmaindivslidermaindiv}>
                <div className={styles.reviewsmaindivimg}>
                  <img src={res.image} alt={res.name} />
                </div>
                <div className={styles.reviewsmaindivcontent}>
                  <div className={styles.reviewsmaindivnamecity}>
                    <div>
                      <p>
                        {res.name}-{"  "}
                      </p>
                    </div>
                    <div>
                      <p>{res.city}</p>
                    </div>
                  </div>
                  <div
                    style={{ width: "90%" }}
                    className={styles.reviewsmaindivaoute}
                  >
                    <p>{res.qoute}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
