import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import styles from "./PrivacyPolicy.module.css";

export default function PrivacyPolicy() {
  return (
    <div>
      <NavBar />
      <div className={styles.privacypolicy}>
        <div className={styles.privacypolicy1}>
          <div className={styles.privacypolicyh2div}>
            <h2>Privacy policy</h2>
          </div>
          <div className={styles.privacypolicyh2divp}>
            <p>
              Your privacy is important to us. This privacy statement explains
              what data Trukrr collects from you, through our interactions with
              you and through our products, and how we use that data. Trukrr
              offers a webpage service to connect Shippers with Carriers for
              transporting goods. References to Trukrr services in this
              statement include websites, software, servers, and devices.
            </p>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            {/* //////////////point 1 ///////////////////////// */}

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <p>
                  1. DATA WE COLLECT Trukrr collects data to operate effectively
                  and provide you with the best experiences with our services.
                  You provide some of this data directly, such as when you
                  create a Trukrr Partnership account, and submit your Name,
                  contact numbers, locations, email, and Company name We get
                  some of it by recording how you interact with our products by,
                  for example, using technologies like cookies, and receiving
                  error reports or usage data from software running on your
                  devices. We also obtain data from third parties.
                </p>
              </div>
              <div className={styles.privacypolicyh2divpart1div2}>
                <div className={styles.privacypolicyh2divpart1div2p}>
                  <p>
                    If you are a Shipper and what to ship a shipment, we collect
                    certain details related to your submission, for example:
                  </p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>&#x2022;Date and time of your submission</p>
                  <p>&#x2022;Origin and destination addresses</p>
                  <p>&#x2022;Date and time of pick-up/drop off</p>
                  <p>&#x2022;Description of the goods being transported</p>
                  <p>&#x2022;Rate, and other information about the Shipment</p>
                </div>
              </div>
              <div className={styles.privacypolicyh2divpart1div2}>
                <div className={styles.privacypolicyh2divpart1div2p}>
                  <p>
                    If you are a Carrier, we collect certain details related to
                    your work such as:
                  </p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>&#x2022;Date and time of your acceptance</p>
                  <p>&#x2022;Driver allocated</p>
                  <p>&#x2022;Timestamps of load milestones</p>
                </div>
              </div>
              <div className={styles.privacypolicyh2divpart1div2}>
                <div className={styles.privacypolicyh2divpart1div2p}>
                  <p>
                    If you are a Driver transporting goods for a Carrier using
                    Trukrr, we collect certain details related to your transport
                    journey such as:
                  </p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>&#x2022;Location</p>
                  <p>&#x2022;Type of vehicle used</p>
                  <p>&#x2022;Scanned and uploaded shipping documents</p>
                  <p>&#x2022;Driver ID proofs and licenses</p>
                </div>
              </div>
            </div>

            {/* //////////////point 2 ///////////////////////// */}

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <p>
                  2. HOW WE USE DATA Trukrr uses the data we collect to operate
                  our business and provide you the services we offer, which
                  includes using data to improve our services and personalise
                  your logistics experiences. We may also use the data to
                  communicate with you, for example, informing you about your
                  account, security updates, and service information. Trukrr
                  will also use data to help show more relevant load
                  information.
                </p>
              </div>
              <div className={styles.privacypolicyh2divpart1div2}>
                <div className={styles.privacypolicyh2divpart1div2p}>
                  <p>2.1 Provide, Improve, and Develop the Trukrr Platform:</p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>
                    &#x2022;Enable you to access and use the Trukrr Platform
                  </p>
                  <p>
                    &#x2022;Enable you to communicate shipments or accept
                    shipments
                  </p>
                  <p>
                    &#x2022;Operate, protect, and improve the platform and
                    experience, such as by performing analytics and conducting
                    research
                  </p>
                  <p>&#x2022;Provide customer service</p>
                  <p>
                    &#x2022;Send you service or support messages, updates,
                    security alerts, and account notifications
                  </p>
                  <p>
                    &#x2022; If you provide us with your contacts’ information,
                    we may process this information: (i) to facilitate your
                    carrier/shipper invitations, (ii) for fraud detection and
                    prevention, and (iii) for any purpose you authorise at the
                    time of collection
                  </p>
                  <p>
                    &#x2022;To operate, protect, improve the Trukrr experience,
                    and customize your logistics experience (such as targeting
                    specific lanes or types of loads)
                  </p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2}>
                  <p>
                    We process this information given our legitimate interest in
                    improving the Trukrr Platform, our user’s experience with
                    it, and where it is necessary for the adequate performance
                    of the contract with you.
                  </p>
                </div>
              </div>
              <div className={styles.privacypolicyh2divpart1div2}>
                <div className={styles.privacypolicyh2divpart1div2p}>
                  <p>
                    2.2 Create and Maintain a Trusted and Safer Environment:
                  </p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>
                    &#x2022;Detect and prevent fraud, spam, abuse, security
                    incidents, and other harmful activity
                  </p>
                  <p>
                    &#x2022;Conduct security investigations and risk assessments
                  </p>
                  <p>
                    &#x2022;Verify or authenticate information or
                    identifications provided by you
                  </p>
                  <p>
                    &#x2022;Conduct checks against databases and other
                    information sources, including background checks, to the
                    extent permitted by applicable laws and with your consent
                    where required
                  </p>
                  <p>&#x2022;Comply with our legal obligations</p>
                  <p>
                    &#x2022;Enforce our Shipper and Carrier Terms of Services
                    and other policies
                  </p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>
                    We process this information given our legitimate interest in
                    protecting the Trukrr Platform, to measure the adequate
                    performance of our contract with you, and to comply with
                    applicable laws.
                  </p>
                </div>
              </div>
            </div>

            {/* //////////////point 3 ///////////////////////// */}

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <p>
                  3. DATA WE SHARE WITH OTHER TRUKRR USERS To facilitate the
                  booking of shipments or other interactions between Shipper and
                  Carrier users, we need to share certain information with other
                  users, as it is necessary for the adequate performance of the
                  contract between you and us, as follows:
                </p>
              </div>
              <div className={styles.privacypolicyh2divpart1div2pp}>
                <p>
                  &#x2022;When you want to send carriers requests for transport
                </p>
                <p>&#x2022;When you want to accept potential load requests</p>
              </div>
              <div className={styles.privacypolicyh2divpart1div2}>
                <div className={styles.privacypolicyh2divpart1div2p}>
                  <p>
                    We may also need to share your information for the following
                    reasons:
                  </p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>
                    &#x2022;With contracted third parties such as consultants,
                    vendors and service providers who require access to such
                    information to carry out work on our behalf
                  </p>
                  <p>
                    &#x2022;Trukrr may disclose your information, including
                    personal information, to courts, law enforcement or
                    governmental authorities, or authorised third parties, if
                    and to the extent we are required or permitted to do so by
                    law or if such disclosure is reasonably necessary: (i)
                    comply with our legal obligations, (ii) to comply with legal
                    process and to respond to claims asserted against Trukrr,
                    (iii) to respond to verified requests relating to a criminal
                    investigation or alleged or suspected illegal activity or
                    any other activity that may expose us, you, or any other of
                    our users to legal liability, (iv) to enforce and administer
                    our Terms of Service, the Payment Terms or other agreements
                    with users, or (v) to protect the rights, property or
                    personal safety of Trukrr, its employees, its users, or
                    members of the public.
                  </p>
                  <p>
                    &#x2022;Where appropriate, we may notify users about legal
                    requests unless (i) providing notice is prohibited by the
                    legal process itself, by court order we receive, or by
                    applicable law, or (ii) we believe that providing notice
                    would be futile, ineffective, create a risk of injury or
                    bodily harm to an individual or group, or create or increase
                    a risk of fraud upon Trukrr’s property, its users, and the
                    Trukrr platform. In instances where we comply with legal
                    requests without notice for these reasons, we will attempt
                    to notify that user about the request after the fact where
                    appropriate and where we determine in good faith that we are
                    no longer prevented from doing so
                  </p>
                </div>
              </div>
            </div>

            {/* //////////////point 4 ///////////////////////// */}

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <p>
                  4. FRAUD PREVENTION Analysing your Communications. We may
                  review, scan, or analyse your communications on the trukrr
                  platform for fraud prevention, risk assessment, regulatory
                  compliance, investigation, product development, research, and
                  customer support purposes. Trukrr carries out these activities
                  out based on a legitimate interest in ensuring compliance with
                  applicable laws and our Terms, preventing fraud, promoting
                  safety, and improving and ensuring the adequate performance of
                  our services.
                </p>
              </div>
            </div>

            {/* //////////////point 5 ///////////////////////// */}

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <p>
                  5. YOUR RIGHTS By sending us an email, users may exercise any
                  of the rights described in this section before your applicable
                  Trukrr Logistics Data Controller.
                </p>
              </div>
              <div className={styles.privacypolicyh2divpart1div2}>
                <div className={styles.privacypolicyh2divpart1div2p}>
                  <p>5.1 Managing Your Information:</p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>
                    You may access and update some of your information through
                    your account settings. You are responsible for keeping your
                    personal information up-to-date.
                  </p>
                </div>
              </div>
              <div className={styles.privacypolicyh2divpart1div2}>
                <div className={styles.privacypolicyh2divpart1div2p}>
                  <p>
                    5.2 Rectification of Inaccurate or Incomplete Information:
                  </p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>
                    &#x2022;You have the right to ask us to correct inaccurate
                    or incomplete personal information concerning you (and which
                    you cannot update yourself within your Trukkr account){" "}
                  </p>
                </div>
              </div>
              <div className={styles.privacypolicyh2divpart1div2p}>
                <p>
                  5.3 Data Access and Portability: In some jurisdictions,
                  applicable law may entitle you to request copies of your
                  personal information held by us. You may also be entitled to
                  request copies of personal information that you have provided
                  to us in a structured, commonly used, and machine-readable
                  format and/or request us to transmit this information to
                  another service provider (where technically feasible)
                </p>
              </div>
              <div className={styles.privacypolicyh2divpart1div2}>
                <div className={styles.privacypolicyh2divpart1div2p}>
                  <p>
                    5.4 Data Retention and Erasure: We generally retain your
                    personal information for as long as is necessary for the
                    performance of the contract between you and us and to comply
                    with our legal obligations. If you no longer want us to use
                    your information to provide Trukrr services to you, you can
                    request that we erase your personal information and close
                    your Trukrr Account. Please note that if you request the
                    erasure of your personal information:
                  </p>
                </div>
                <div className={styles.privacypolicyh2divpart1div2pp}>
                  <p>
                    &#x2022;We may retain some of your personal information as
                    necessary for our legitimate business interests, such as
                    fraud detection and prevention and enhancing safety
                  </p>
                  <p>
                    &#x2022;We may retain and use your personal information to
                    the extent necessary to comply with our legal obligations.
                  </p>
                </div>
              </div>
              <div className={styles.privacypolicyh2divpart1div2p}>
                <p>
                  We may retain and use your personal information to the extent
                  necessary to comply with our legal obligations. The
                  information you have shared with others (e.g., Reviews,
                  Testimonials) may continue to be publicly visible on the
                  Trukrr Platform, even after your account is canceled. However,
                  the attribution of such information to you will be removed.
                  Additionally, some copies of your information (e.g., log
                  records) may remain in our database but are disassociated from
                  personal identifiers. Because we maintain the Trukrr Platform
                  to protect from accidental or malicious loss and destruction,
                  residual copies of your personal information may not be
                  removed from our backup systems for a limited period.
                </p>
              </div>
              <div className={styles.privacypolicyh2divpart1div2p}>
                <p>
                  5.5 Withdrawing Consent and Restriction of Processing: Where
                  you have provided your consent to the processing of your
                  personal information by Trukrr you may withdraw your consent
                  at any time by changing your Account settings or by sending a
                  communication to Trukrr specifying which consent you are
                  withdrawing. Please note that the withdrawal of your consent
                  does not affect the lawfulness of any processing activities
                  based on such consent before its withdrawal. Additionally, in
                  some jurisdictions, applicable law may give you the right to
                  limit the ways in which we use your personal information, in
                  particular, where (i) you contest the accuracy of your
                  personal information; (ii) the processing is unlawful and you
                  oppose the erasure of your personal information; (iii) we no
                  longer need your personal information for the purposes of the
                  processing, but you require the information for the
                  establishment, exercise or defense of legal claims.
                </p>
              </div>
              <div className={styles.privacypolicyh2divpart1div2p}>
                <p>
                  5.6 Lodging Complaints: You have the right to lodge complaints
                  about the data processing activities carried out by Tukrr
                  before the competent data protection authorities. Your Data
                  Controller Tekkr Technologies Private Limited, you have the
                  right to file a complaint with its lead supervisory authority,
                  the Indian Data Protection Commissioner.
                </p>
              </div>
            </div>

            {/* //////////////point 6 ///////////////////////// */}

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <p>
                  6. SECURITY We are continuously implementing and updating
                  administrative, technical, and physical security measures to
                  help protect your information against unauthorised access,
                  loss, destruction, or alteration. Some of the safeguards we
                  use to protect your information are firewalls and data
                  encryption, and information access controls. If you know or
                  have reason to believe that your Trukrr Account credentials
                  have been lost, stolen, misappropriated, or otherwise
                  compromised, or in case of any actual or suspected
                  unauthorized use of your Trukrr Account, please contact us
                  following the instructions in the Contact Us section below.
                </p>
              </div>
            </div>

            {/* //////////////point 7 ///////////////////////// */}
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <p>
                  7. CHANGES TO THIS PRIVACY POLICY Trukrr reserves the right to
                  amend this Privacy Policy at any time in accordance with this
                  provision. If we make changes to this Privacy Policy, we will
                  post the revised Privacy Policy on the Trukrr Platform and
                  update the “Last Updated” date at the top of this Privacy
                  Policy. We will also provide you with notice of the amendment
                  by email at least thirty (30) days before the date they become
                  effective. If you disagree with the revised Privacy Policy,
                  you may cancel your Account. If you do not cancel your Account
                  before the date the revised Privacy Policy becomes effective,
                  your continued access to or use of the Trukrr Platform will be
                  subject to the revised Privacy Policy.
                </p>
              </div>
            </div>

            {/* //////////////point 8 ///////////////////////// */}

            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <p>
                  8. CONTACT US If you have any questions or complaints about
                  this Privacy Policy or Trukrr’s information handling
                  practices, you may email us or contact us at:
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
