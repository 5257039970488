import React, { useEffect } from "react";
import styles from "./ExploreTruck.module.css";
import explrtruck from "../../../assets/explrtruck.png";
import WhyChooseexplore from "../WhyChooseexplore/WhyChooseexplore";
import PartnerReview from "../PartnerReview/PartnerReview";
import logo from "../../../assets/baccc.png";

import Aos from "aos";
import "aos/dist/aos.css";
export default function ExploreTruck(props) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={styles.introtruckdivmainnnnn}>
      <div className={styles.introtruckdiv}>
        <div className={styles.introtruckdivimg}>
          <img src={explrtruck} alt="explrtruck" />
        </div>
        <div className={styles.introtruckdivimgcontent}>
          <div data-aos="fade-right">
            <h2>
              Explore Corporate Solutions with{" "}
              <span>
                <img
                  className={styles.introtruckdivimg}
                  src={logo}
                  alt="logo"
                />
              </span>{" "}
            </h2>
          </div>
          <div data-aos="fade-left">
            <p>
              At Trukrr we leverage technology and real-time information for
              effective
              <br /> and efficient logistics management.
            </p>
          </div>
          <div>
            <button onClick={props.handleOpenmat} className={styles.btn}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <WhyChooseexplore />
      <PartnerReview />
    </div>
  );
}
