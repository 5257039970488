import React from "react";
import styles from "./Clients.module.css";
import handshakevector from "../../../assets/handshakevector.png";

import zomoto from "../../../assets/zomato.png";
import amazon from "../../../assets/amazon.png";
import delhivery from "../../../assets/delhivery.png";
import myntra from "../../../assets/myntra.png";
import Flipkart from "../../../assets/Flipkart.png";
import wakefit from "../../../assets/wakefit.png";
import guarented from "../../../assets/guarented.png";
import shadowfaz from "../../../assets/Shadowfax.png";
import udan from "../../../assets/udan.png";
import waycool from "../../../assets/waycool.png";
import oladash from "../../../assets/oladash.png";
import godrej from "../../../assets/godrej.png";
import bigbasket from "../../../assets/bigbasket.png";
import pantaloons from "../../../assets/pantaloons.png";
import maersk from "../../../assets/maersk.png";
import mahindra from "../../../assets/mahindra.png";
import easybox from "../../../assets/easybox.png";
import wingreen from "../../../assets/wingreens.avif"
import arzoo from "../../../assets/arzoo.webp"

import bccc from "../../../assets/bcccc.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arwcstmrt from "../../../assets/arwcstmrt.png";

export default function Clients() {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arwcstmrt} className={styles.classNameimg13} alt="arw1" />
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arwcstmrt} className={styles.classNameimg13} alt="nxtarw" />
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],

    // nextArrow: <SamplePrevArrow />,
    // prevArrow: <SampleNextArrow />,
  };
  const settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: -1,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    // nextArrow: <SamplePrevArrow />,
    // prevArrow: <SampleNextArrow />,
  };
  return (
    <div className={styles.clientsmaindiv}>
      <div className={styles.clientsmaindivbccc}>
        <img src={bccc} alt="bccc" />
      </div>
      <div className={styles.clientsmaindiv22}>
        <div className={styles.clientsmaindivh1img}>
          <div className={styles.clientsmaindivh2}>
            <h2>Some of our clients include</h2>
          </div>
          <div className={styles.clientsmaindivimg}>
            <img src={handshakevector} alt="Clients" />
          </div>
        </div>

        <div className={styles.clientsmaindivclients1}>
          <Slider {...settings}>
            <div className={styles.clien1}>
              <img src={amazon} alt="Clients" style={{ height: "30px" }} />
            </div>
            <div className={styles.clien1}>
              <img src={waycool} alt="Clients" style={{ height: "30px" }} />
            </div>
            <div className={styles.clien1}>
              <img src={delhivery} alt="Clients" style={{ height: "30px" }} />
            </div>
            <div className={styles.clien1}>
              <img src={wakefit} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={guarented} alt="Clients" style={{ height: "30px" }} />
            </div>
            <div className={styles.clien1}>
              <img src={shadowfaz} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={godrej} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={pantaloons} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={maersk} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={mahindra} alt="Clients" />
            </div>
          </Slider>
        </div>
        <div className={styles.clientsmaindivclients1}>
          <Slider {...settings2}>
            <div className={styles.clien1}>
              <img src={Flipkart} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={zomoto} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={myntra} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={oladash} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={udan} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={bigbasket} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={easybox} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={wingreen} alt="Clients" />
            </div>
            <div className={styles.clien1}>
              <img src={arzoo} alt="Clients" />
            </div>
          </Slider>
        </div>
        {/* <Slider {...settings}></Slider> */}

        <div className={styles.clientsmaindivclientsp}>
          <p>We are trusted by the biggest names in e-commerce industry</p>
        </div>
      </div>
    </div>
  );
}
