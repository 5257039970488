import React, { useState } from "react";
import NavBar from "../../NavBar/NavBar";
import Footer from "../../Footer/Footer";
import AboutusMain from "../AboutusMain/AboutusMain";
import OurHeighlights from "../OurHeighlights/OurHeighlights";

export default function Aboutus() {
  return (
    <div>
      <NavBar
        style={{
          position: "sticky",
          zIndex: "5",
          width: "100%",
          backgroundColor: "transparent",
        }}
        styles={{ color: "#000" }}
      />
      <AboutusMain />
      <OurHeighlights />
      <Footer />
    </div>
  );
}
