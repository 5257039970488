import React, { useEffect, useState } from "react";
import bgm from "../../../assets/ff1.png";
import bggg from "../../../assets/bggggu.png";
import dot from "../../../assets/dot.png";

import gifygif from "../../../assets/gifyfy.gif";

import grpmob from "../../../assets/grpmob.png";

import Aos from "aos";
import "aos/dist/aos.css";
import styles from "./LandingPage.module.css";
import cx from "classnames";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function LandingPage() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className={styles.bckdivmaindiv} id="top">
      <div className={styles.bckdivmobiii}></div>
      <div className={styles.bckdiv}>
        {/* <img src={dot} alt="truckae" className={styles.bckdivbgdot} /> */}
        <img src={bggg} alt="truckae" className={styles.bckdivbg} />
        <img src={bgm} alt="truckae" className={styles.bckdivbgm} />
      </div>
      <div className={styles.textandbckgrd}>
        <div className={styles.textandbckgrdhpmaindiv}>
          <div className={styles.textandbckgrdhpmaindivh1h1}>
            <h1 data-aos="fade-right" data-aos-duration="700">
              Making logistics easier
            </h1>
            <h1 data-aos="fade-right" data-aos-duration="700">
              and hassle free
            </h1>
          </div>
          <div className={styles.textandbckgrdhpmaindiv2}>
            <p data-aos="fade-left" data-aos-duration="1000">
              India's most efficient on demand truck provider
            </p>
          </div>
        </div>
        <div className={styles.textandbckgrdimgdiv}>
          <div>
            <img className={styles.mobimg} src={grpmob} alt="truckae-mobile" />
          </div>
          <div className={styles.textandbckgrdimgdiv222}>
            <div className={styles.textandbckgrdimgdiv222sml}>
              <img
                className={styles.mobimg3}
                src={gifygif}
                alt="truckae-mobile"
              />
            </div>

            <div className={styles.shadoww}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
