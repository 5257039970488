import React, { useState } from "react";
import NavBar from "../../NavBar/NavBar";
import DriverPartnerbtns from "../DriverPartnerbtns/DriverPartnerbtns";
import IntroduceTruck from "../IntroduceTruck/IntroduceTruck";
import Footer from "../../Footer/Footer";
import ExploreTruck from "../ExploreTruck/ExploreTruck";
import Review from "../Review/Review";
import DriverModal from "../DriverModal/DriverModal";
import DriverModalmat from "../DriverModal/DriverModalmat";
import axios from "axios";
import GetBackToYou from "../GetBackToYou/GetBackToYou";

export default function PartnershipPage() {
  const [driverData, setDriverData] = useState({
    name: "",
    mobileNumber: "",
    place: "",
  });

  const [partnerData, setPartnerData] = useState({
    name: "",
    mobileNumber: "",
    place: "",
    companyName: "",
    companyEmail: "",
    typeOfService: "",
  });

  const [driverSuccess, setDriverSuccess] = useState(false);
  const [partnerSuccess, setPartnerSuccess] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    console.log("ss");
    setOpen(true);
    setDriverSuccess(false);
  };
  const handleClose = () => setOpen(false);

  const [openmat, setOpenmat] = React.useState(false);
  const handleOpenmat = () => {
    setOpenmat(true);
    setPartnerSuccess(false);
  };
  const handleClosemat = () => setOpenmat(false);

  const [driverShow, setDriverShow] = useState("driver");

  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    setModal(e);
    console.log("ssss");
  };
  const driverFn = () => {
    setDriverShow("driver");
  };
  const partnerFn = () => {
    setDriverShow("partner");
  };

  const driverOnchange = (e) => {
    const newData = { ...driverData };
    newData[e.target.id] = e.target.value;
    setDriverData(newData);
    console.log("sss", newData);
    console.log("sss", e);
  };

  const partnerOnchange = (e) => {
    console.log("id:", e.target.name, " value:", e.target.value);
    const newData = { ...partnerData };
    newData[e.target.name] = e.target.value;
    setPartnerData(newData);
    console.log("sss", newData);
    console.log("sssvalue", e.target.value);
  };

  const postDriverData = () => {
    const body = {
      name: driverData.name,
      mobileNumber: driverData.mobileNumber,
      place: driverData.place,
    };
    console.log("postDriverData", body);
    axios
      .post("http://backend.trukrr.com/api/v1/users/WebDriverAPI/", body)
      .then((res) => {
        if (res.data.status === "success") {
          setDriverSuccess(true);
          setDriverData("");
        }

        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postPartnersData = () => {
    console.log("postPartnersDatasss", partnerData.typeOfService);
    const body = {
      companyName: partnerData.companyName,
      companyEmail: partnerData.companyEmail,
      typeOfService: partnerData.typeOfService,
      name: partnerData.name,
      mobileNumber: partnerData.mobileNumber,
      place: partnerData.place,
    };
    console.log("postPartnersData", body);
    axios
      .post("http://backend.trukrr.com/api/v1/users/PartnerAPI/", body)
      .then((res) => {
        if (res.data.status === "success") {
          setPartnerSuccess(true);
          setPartnerData("");
        }

        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <NavBar
        style={{
          position: "sticky",
          zIndex: "5",
          width: "100%",
          top: "10px",
          backgroundColor: "transparent",
          margin: "45px auto -115px",
        }}
        styles={{ color: "#000" }}
      />
      <DriverPartnerbtns
        driverFn={driverFn}
        partnerFn={partnerFn}
        driverShow={driverShow}
      />
      {driverShow === "driver" ? (
        <IntroduceTruck handleOpen={handleOpen} handleClose={handleClose} />
      ) : driverShow === "partner" ? (
        <ExploreTruck
          handleOpenmat={handleOpenmat}
          handleClose={handleClosemat}
        />
      ) : (
        ""
      )}

      <DriverModal
        modal={modal}
        toggle={toggle}
        open={open}
        handleClose={handleClose}
        postDriverData={postDriverData}
        driverData={driverData}
        driverOnchange={driverOnchange}
        driverSuccess={driverSuccess}
      />

      <DriverModalmat
        openmat={openmat}
        handleOpenmat={handleOpenmat}
        handleClosemat={handleClosemat}
        postPartnersData={postPartnersData}
        partnerData={partnerData}
        partnerOnchange={partnerOnchange}
        partnerSuccess={partnerSuccess}
      />
      <Footer />
    </div>
  );
}
