import React, { useEffect, useState } from "react";
import styles from "./WhyChooseexplore.module.css";
import meeting from "../../../assets/blb.png";
import profit2 from "../../../assets/grppeople.png";
import salary from "../../../assets/profit21.png";
import Aos from "aos";
import "aos/dist/aos.css";
import ellipse1 from "../../../assets/ellipse1.png";
import ellipse3 from "../../../assets/ellipse3.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function WhyChooseexplore() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={styles.whychoosemaindiv}>
      <div className={styles.whychoosemaindivdiv}>
        <div className={styles.whychoosemaindivel}>
          <img src={ellipse1} alt="ellipse1" />
        </div>
        <div className={styles.whychoosemaindivel2}>
          <img src={ellipse3} alt="ellipse1" />
        </div>
      </div>
      <div data-aos="zoom-out-right" className={styles.whychoosemaindivh2mainn}>
        <div className={styles.whychoosemaindivmain}>
          <div className={styles.whychoosemaindivh2}>
            <h2>Why choose us?</h2>
          </div>
          <div className={styles.whychoosemaindivh222}>
            <div className={styles.whychoosemaindivh222m}>
              <div>
                <img src={meeting} alt="meeting" />
              </div>
              <div>
                <h3>Top Class Personalized Tech Solutions</h3>
              </div>
              <div className={styles.whychoosemaindivh222mpdiv}>
                <p>
                  Our Logistic service gives you a Robust Technology Logistic
                  solution — everything is done according to your needs, and
                  customers need to pay for only the services they require.We
                  will work with you to learn more about your business and
                  operations as we proceed to ensure the type of solution that
                  suits you.
                </p>
              </div>
            </div>
            <div className={styles.whychoosemaindivh222m}>
              <div>
                <img src={profit2} alt="meeting" />
              </div>
              <div>
                <h3>Friendly & Comprehensive Services</h3>
              </div>
              <div className={styles.whychoosemaindivh222mpdiv}>
                <p>
                  Our friendly and dedicated managers will meet you personally
                  to discuss deeply regarding your needs. You can expect to have
                  us to take care of your goods from start to finish
                </p>
              </div>
            </div>
            <div className={styles.whychoosemaindivh222m}>
              <div>
                <img src={salary} alt="meeting" />
              </div>
              <div>
                <h3>Higher Profits for Your Business</h3>
              </div>
              <div className={styles.whychoosemaindivh222mpdiv}>
                <p>
                  We provide you with a comprehensive supply chain management
                  service by upgrading the effectiveness of your current
                  logistics. Next, we keep your overall cost down by managing
                  the entire process from start to finish, so you can focus on
                  your core business.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.whychoosemaindivtake}>
          <div className={styles.whychoosemaindivtakeh2}>
            <h2>Take a look at what our drivers say</h2>
          </div>
          <Slider {...settings}>
            <div></div>
          </Slider>
        </div> */}
      </div>
    </div>
  );
}
