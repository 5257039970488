import React, { useState, useEffect } from "react";
import Styles from "./NavBar.module.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/baccc.png";

export default function NavBar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useState("");
  let navigate = useNavigate();

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const closeit = () => {
    setIsOpen(false);
  };

  // useEffect(() => {
  //   const t = get_cookie("token");
  //   setToken(t);
  // }, [token]);

  useEffect(() => {}, [token]);

  // const logout = () => {
  //   // axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/register/`);
  //   set_cookie("token", "");
  //   window.location.reload(false);
  // };

  return (
    <div style={props.style} className={Styles.navmaindi22v}>
      <div className={Styles.navmaindiv}>
        <Navbar light expand="md" className={Styles.navmaindiv111}>
          <div className={Styles.navmaindivlogo}>
            <NavbarBrand href="/" style={props.styles}>
              <img src={logo} alt="logo" className={Styles.img} />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
          </div>
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className={Styles.nav2}>
              <NavItem>
                <NavLink
                  href={"/#business"}
                  className={Styles.navlink}
                  style={props.styles}
                  onClick={closeit}
                >
                  Business
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  exact
                  tag={Link}
                  activeClassName={Styles.active}
                  to="/partnership"
                  style={props.styles}
                  className={Styles.navlink}
                  onClick={closeit}
                >
                  Partnership
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  exact
                  tag={Link}
                  activeClassName={Styles.active}
                  to="/about-us"
                  style={props.styles}
                  className={Styles.navlink}
                  onClick={closeit}
                >
                  About Us
                </NavLink>
              </NavItem>{" "}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
}
