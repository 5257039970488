import React from "react";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import styles from "./FAndQ.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAndQ() {
  const content = [
    {
      heading: "How Can I be your partner?",
      contentp:
        "Our Partnership process is a simplified one. You can place a partnership request online on our website or you can call our customer care number 987654321 Our staff will be in touch with you soon.",
    },
    {
      heading: "What Does It Cost to become your partner?",
      contentp:
        "There is no fee or monthly subscription for signing up or accessing. Trukrr is 100% free of charge.",
      heading2: "How would I know my pay slabs & bill amount?",

      contentp2:
        "Once our system receives your partnership confirmation, you will be notified of the approximate Pay slabs for Drivers, and Billing models for partners. With our Technology-assisted dashboards, we instantaneously give you the best rate online.",
    },
    {
      heading: "What Can I Ship?",
      contentp:
        "We support anything you wish to deliver to any location. Please contact us for any specific requirements at 987654321 or drop a mail to support@trukkr.com",
    },
    {
      heading: " What Warranties Do I Have for My Shipments?",
      contentp:
        "Trukrr operates under company-specific freight forward terms and Conditions. We are business partners with Major Logistics and Freight managing companies. Trukrr can also offer specific different warranty models on request. We regularly review our transport providers and only work with professional and experienced drivers.",
    },
    {
      heading: " How Does Trukrr Verify Carriers?",
      contentp:
        "We take pride in ensuring the quality of our Carriers. All Carriers are pre-screened and verified before they start carrying your freight. We regularly monitor compliance documentation and review Carrier performance.",
    },
    {
      heading: "Can I Add My Existing Carriers to Trukrr?",
      contentp:
        "Absolutely, we welcome existing carriers, allowing you to earn more based on your flexibility.",
    },
    {
      heading: "When Do Carriers Get Paid On Trukrr?",
      contentp:
        "Trukrr pays carriers within 30 days after proof of delivery (POD) is scanned and verified by the clients and Trukrr.",
    },
    {
      heading: " Who Can Use Trukrr?",
      contentp:
        "Trukrr is a B2B platform allowing businesses of any form with readily available shipments to be linked to the customer.",
      contentp2:
        "Drivers or truck owners who are looking for flexible working times, payment options, and fixed salaries can use Trukrr to better their life.",
    },
    {
      heading: " After Completing a Delivery, How Do I Get Paid?",
      contentp:
        "After the digitalized proof of delivery (POD) is uploaded to the platform, payment will be received within 30 days or the payment model.",
    },
    {
      heading:
        "After Registering as A Carrier, When Can I Begin Accepting Jobs?",
      contentp:
        "Once you registered as a Carrier, we will review your application to verify your ability to handle our shippers’ freight and provide and required training Provided as per Trukrr Noams. After that drivers may begin to start work with us.",
    },
    {
      heading:
        " What Differentiates Trukrr from A Traditional Logistic Company?",
      contentp:
        "Trukrr provides a digital solution for the road freight industry. We eliminate the typical manual processes (multiple emails, long phone calls, chasing down trucks and invoices) and replace them with one centralized platform for all logistics needs. Our online services and automated processes reduced manual errors, and provide track & trace technology, making shipping simpler and more efficient.",
    },
    {
      heading: "What Locations Does Trukrr Works?",
      contentp:
        "Trukrr can handle shipments via road freight throughout India.",
    },
  ];
  return (
    <div>
      <NavBar />
      <div className={styles.fandqmindiv}>
        <div className={styles.fandqmindivh2}>
          <h2>Frequently asked Questions</h2>
        </div>
        {content.map((res, i) => (
          <div className={styles.fandqmindiv2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className={styles.fandqmindiv2h2}>
                    <h2>
                      <span>{i + 1}.</span> {res.heading}
                    </h2>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className={styles.fandqmindiv2p}>
                    <p>{res.contentp}</p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
